
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import MyListDropdown from '@/components/User/MyListDropdown.vue'
import UserDropdown from '@/components/User/UserDropdown.vue'
import NotificationTray from '@/components/Notification/NotificationTray.vue'
import AuthImg from '@/components/AuthImg.vue'
import MainLogo from '@/assets/img/main-logo.svg'
import { BellNotificationIcon, UserIcon, ChatIcon, SearchIcon, MedicalPersonIcon, MenuIcon, Scissors } from '@/icons'
import OperationSheet from '@/models/entities/OperationSheet'
import { User } from '@/models/entities/User'
import Report from '@/views/Report.vue'
import { axios } from '@/plugins/axios'
import InstrumentSearchModal from './Instrumentation/Search.vue'

const chat = namespace('ChatModule')
const notification = namespace('NotificationModule')
const user = namespace('UserModule')

/**
 * The application navigation bar
 */
@Component({
  components: {
    MyListDropdown,
    NotificationTray,
    UserDropdown,
    AuthImg,
    BellNotificationIcon,
    UserIcon,
    ChatIcon,
    MenuIcon,
    MainLogo,
    Report,
    InstrumentSearchModal
  },
})
export default class Navbar extends Vue {
  links = [
    {
      label: 'Instrumentation',
      to: '/instrumentation',
      icon: Scissors,
      admin: false,
    },
    {
      label: this.$tc('pages.plans'),
      to: '/plans',
      icon: SearchIcon,
      admin: false,
    },
    {
      label: this.$tc('pages.staffs'),
      to: '/staffs',
      icon: MedicalPersonIcon,
      admin: false,
    },
    {
      label: this.$tc('pages.users'),
      to: '/users',
      icon: UserIcon,
      admin: true,
    },
  ]

  pendingReportCount = 0

  @user.State
  myList!: OperationSheet[]

  @user.State
  connectedUser!: User

  @chat.State
  unreadMessagesCount!: number

  @user.Getter
  isUserAdmin!: boolean

  @notification.Getter
  unreadNotificationsCount!: number

  @notification.Getter
  unreadNotificationsCountForToday!: number

  instrumentSearchModalIsOpen: boolean = false;

  created() {
    this.getMyList()
  }

  async mounted() {
    const { data: pendingCount } = await axios.get(`/api/report/pending-count`);

    this.pendingReportCount = pendingCount;
  }

  closeInstrumentSearchModal() {
    this.instrumentSearchModalIsOpen = false
  }

  isCurrentPage(name: string) {
    return this.$route.fullPath?.toLowerCase() === name
  }

  goToChatView() {
    return this.$router.push('/chat')
  }

  removeFromList(sheet: OperationSheet) {
    this.removeFromMyList(sheet).then(() => {
      this.$flashSuccess(sheet.name, this.$tc('plans.my_list.removed'))
      this.getMyList()
    })
  }

  @notification.Action
  toggleNotificationTray!: () => void

  @user.Action
  toggleUserTray!: () => void

  @user.Action
  removeFromMyList!: (sheet: OperationSheet) => Promise<void>

  @user.Action
  getMyList!: () => void
}
