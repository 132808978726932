
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { User } from '@/models/entities/User'
import AuthImg from '@/components/AuthImg.vue'

const user = namespace('UserModule')

/**
 * The user tray component displays the user's information and the logout button
 */
@Component({
  components: {
    AuthImg,
  },
})
export default class UserDropdown extends Vue {
  @user.State
  isUserTrayOpen!: boolean

  @user.State
  connectedUser!: User

  @user.Action
  logout!: () => void

  @user.Action
  toggleUserTray!: () => void

  linkToProfile() {
    this.toggleUserTray()
    this.$router.push({ name: 'Profile' })
  }

  async signout() {
    this.toggleUserTray()
    this.logout()
  }
}
