import { axios } from '@/plugins/axios'
import { config, Action, VuexModule, Module } from 'vuex-module-decorators'
import { Message } from '../../models/entities/chat/message'

config.rawError = true

@Module({ namespaced: true })
export default class ChatModule extends VuexModule {
  allChatMessages_: Record<string, Message[]> = {};

  get allChatMessages() {
    return this.allChatMessages_;
  }

  get chatMessagesForUser() {
    return (userId: string) => this.allChatMessages[userId]
  }

  @Action
  async getAllChatMessagesForCurrentlyAuthenticatedUser() {
    const { data: responseData } = await axios.get<Record<string, Record<string, unknown>[]>>('/api/chat/messages')

    for (const [otherUserId, messages] of Object.entries(responseData)) {
      this.allChatMessages_[otherUserId] = messages.map((messageData) => Message.createFromObject(messageData))
    }
  }

  @Action
  async sendMessage({ messageBody, receiverId }: { messageBody: string, receiverId: string }) {
    const { status: responseStatus, data: receivedMessageData } = await axios.post(`/api/chat/message/${receiverId}`, {
      message: messageBody
    })

    if (responseStatus !== 201) {
      console.error(`Error when sending chat message. Expected HTTP 201; got ${responseStatus}.`);
    }

    const sentMessage = Message.createFromObject(receivedMessageData)

    if (!this.allChatMessages[receiverId]) {
      this.allChatMessages[receiverId] = []
    }

    this.allChatMessages[receiverId].push(sentMessage)

    return sentMessage;
  }

  @Action
  async broadcastMessage({ messageBody, receivers }: { messageBody: string, receivers: string }) {
    const { status: responseStatus } = await axios.post(`/api/chat/broadcast/${receivers}`, {
      message: messageBody
    })

    if (responseStatus !== 201) {
      console.error(`Error when BROADCASTING chat message. Expected HTTP 201; got ${responseStatus}.`);
    }
  }

  @Action
  addNewMessageForUser({ message: messageData, userId }: { message: Message, userId: string }) {
    if (!this.allChatMessages[userId]) {
      this.allChatMessages[userId] = []
    }

    this.allChatMessages[userId].push(Message.createFromObject(messageData))
  }
}
