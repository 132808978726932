import Vue from 'vue'
import { config, VuexModule, Module, MutationAction, Action, Mutation } from 'vuex-module-decorators'
import { request, exposableRequest, axios } from '@/plugins/axios'

import SurgeryObject, { SurgeryObjectType } from '@/models/entities/Objects/SurgeryObject'
import OperationSheet from '@/models/entities/OperationSheet'
import RessourceSearch from '@/models/ressourcesSearch/RessourceSearch'
import IParameter from '@/models/interfaces/sheets/IParameter'
import { IComment } from '@/models/interfaces/sheets/IComment'
import TPAComplex from '@/models/entities/Objects/TPAComplex'
import Column from '@/models/entities/Objects/Column/Column'
import Stand from '@/models/entities/Objects/Column/Stand'
import { SurgeryStaffType } from '@/models/entities/Person/SurgeryStaff'
import EmbeddedColumn from '@/models/entities/Objects/Column/EmbeddedColumn'

config.rawError = true

/**
 * Module for operations on a single operation sheet (edition)
 */
@Module({ namespaced: true })
class OperationSheetModule extends VuexModule {
  currentSheet!: OperationSheet

  selectedItem!: SurgeryObject

  selectedItemWrapperId!: string

  isToolsTableSelectionTrayOpen: boolean = false

  isStaffSelectionTrayOpen: boolean = false

  isDeviceSelectionTrayOpen: boolean = false

  isBedSelectionTrayOpen: boolean = false

  isColumnSelectionTrayOpen: boolean = false

  isSelectedToolTrayOpen: boolean = false

  isPatientSelectionTrayOpen: boolean = false

  isOperationTableSelectionTrayOpen: boolean = false

  isAccessorySelectionTrayOpen: boolean = false

  unsavedChanges: boolean = false

  objectsByType: Map<SurgeryObjectType, RessourceSearch<SurgeryObject>> = new Map()

  unLocatedPlans: OperationSheet[] = []

  staffDisplayNames: Array<{ wrapperId: string; name: string; staffId: string; staffType: SurgeryStaffType }> = []

  isBedLocked: boolean = false

  isObjectsLocked: boolean = false

  isStaffsLocked: boolean = false

  @Mutation
  changesNeedSaving() {
    this.unsavedChanges = true;
  }

  @Mutation
  setStaffDisplayName(staff: { wrapperId: string; name: string; staffId: string; staffType: SurgeryStaffType }) {
    const index = this.staffDisplayNames.findIndex((s) => s.wrapperId === staff.wrapperId)
    if (index !== -1) {
      Vue.set(this.staffDisplayNames, index, staff)
    } else {
      this.staffDisplayNames.push(staff)
    }
  }

  @Mutation
  setSelectedItemWrapperId(id: string) {
    this.selectedItemWrapperId = id
  }

  @Mutation
  cancelLocks() {
    this.isBedLocked = false
    this.isObjectsLocked = false
    this.isStaffsLocked = false
  }

  get staffDisplayName(): (staffWrapperId: string) => string {
    return (staffWrapperId: string) => this.staffDisplayNames.find((s) => s.wrapperId === staffWrapperId)?.name || ''
  }

  get staffFromWrapperId() {
    return (staffWrapperId: string) => this.staffDisplayNames.find((s) => s.wrapperId === staffWrapperId)?.staffId || ''
  }

  get filteredObjectsByType() {
    return (type: SurgeryObjectType) => {
      const searcher = this.objectsByType.get(type)

      if (!searcher) {
        return []
      }

      const searchTerms = searcher.search.split(' ')
      const objects = searcher.ressources
        ? searcher.ressources.filter((object) =>
            object.searchableContent().some((filter) => searchTerms.every((term) => filter.toLowerCase().includes(term)))
          )
        : []

      return objects.sort((a, b) => a.sortBy().localeCompare(b.sortBy()))
    }
  }

  @Action
  async saveSheet() {
    await exposableRequest(
      {
        method: 'patch',
        url: `/api/operation-sheets/${this.currentSheet.id}`,
      },
      this.currentSheet,
      OperationSheet
    )
  }

  @Action
  async uploadFile(planFile: File): Promise<string> {
    const file = new FormData()
    file.append('file', planFile)

    const response = await axios.post(`/api/files/operationSheet/${this.currentSheet.id}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data.pdfFileName
  }

  @Action
  async uploadToolsTableListing(toolsTableListing: File): Promise<string> {
    const file = new FormData()
    file.append('file', toolsTableListing)

    const response = await axios.post('/api/files/toolsTables', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data.filename
  }

  @Action
  async uploadToolsTablePicture(toolsTablePicture: File): Promise<string> {
    const file = new FormData()
    file.append('file', toolsTablePicture)

    const response = await axios.post('/api/images/toolsTables', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data.filename
  }

  @MutationAction
  async toggleToolsTableSelectionTray() {
    return { isToolsTableSelectionTrayOpen: !this.isToolsTableSelectionTrayOpen }
  }

  @MutationAction
  async toggleStaffSelectionTray() {
    return { isStaffSelectionTrayOpen: !this.isStaffSelectionTrayOpen }
  }

  @MutationAction
  async toggleDeviceSelectionTray() {
    return { isDeviceSelectionTrayOpen: !this.isDeviceSelectionTrayOpen }
  }

  @MutationAction
  async toggleBedSelectionTray() {
    return { isBedSelectionTrayOpen: !this.isBedSelectionTrayOpen }
  }

  @MutationAction
  async toggleColumnSelectionTray() {
    return { isColumnSelectionTrayOpen: !this.isColumnSelectionTrayOpen }
  }

  @MutationAction
  async togglePatientSelectionTray() {
    return { isPatientSelectionTrayOpen: !this.isPatientSelectionTrayOpen }
  }

  @MutationAction
  async toggleOperationTableSelectionTray() {
    return { isOperationTableSelectionTrayOpen: !this.isOperationTableSelectionTrayOpen }
  }

  @MutationAction
  async toggleAccessorySelectionTray() {
    return { isAccessorySelectionTrayOpen: !this.isAccessorySelectionTrayOpen }
  }

  @MutationAction
  async toggleIsBedLocked() {
    return { isBedLocked: !this.isBedLocked }
  }

  @MutationAction
  async toggleIsObjectsLocked() {
    return { isObjectsLocked: !this.isObjectsLocked }
  }

  @MutationAction
  async toggleIsStaffsLocked() {
    return { isStaffsLocked: !this.isStaffsLocked }
  }

  @MutationAction
  async toggleSelectedItemTray(item: SurgeryObject) {
    // if (item && this.selectedItem && item.id !== this.selectedItem.id) {
    //   return { selectedItem: item, isSelectedToolTrayOpen: true }
    // }

    return {
      selectedItem: item,
      isSelectedToolTrayOpen: !this.isSelectedToolTrayOpen,
    }
  }

  @MutationAction
  async setSelectedItem(item: SurgeryObject) {
    return { selectedItem: item }
  }

  @MutationAction
  async setUnsavedChanges(unsavedChanges: boolean) {
    return { unsavedChanges }
  }

  @MutationAction
  async applySearch(terms: { search: string; type: SurgeryObjectType }) {
    const searcher = this.objectsByType.get(terms.type)

    if (!searcher) {
      return
    }

    searcher.search = terms.search.trim()

    return {
      objectsByType: new Map(this.objectsByType.set(terms.type, searcher)),
    }
  }

  @MutationAction
  async getCurrentSheet(id: string) {
    const currentSheet = await request<OperationSheet>(
      {
        method: 'get',
        url: `/api/operation-sheets/${id}`,
      },
      OperationSheet
    )

    return { currentSheet }
  }

  @MutationAction
  async getObjectsByType(type: SurgeryObjectType) {
    const objects = await request<SurgeryObject[]>(
      {
        method: 'get',
        url: `/api/surgery-objects?type=${type}`,
      },
      SurgeryObject
    )

    return { objectsByType: new Map(this.objectsByType.set(type, new RessourceSearch(type, objects))) }
  }

  @MutationAction
  async getUnLocatedPlans() {
    const plans = await request<OperationSheet[]>(
      {
        method: 'get',
        url: '/api/operation-sheets?onlyWithoutLocation=true',
      },
      OperationSheet
    )

    return { unLocatedPlans: plans }
  }

  @Action
  async getObjectParameterRelatedPlans(terms: {
    oldParameter: IParameter<unknown>
    objectId: string
  }): Promise<OperationSheet[]> {
    const plans = await request<OperationSheet[]>(
      {
        method: 'post',
        url: `/api/operation-sheets/${this.currentSheet.id}/object/${terms.objectId}/parameter`,
        data: { parameter: terms.oldParameter },
      },
      OperationSheet
    )
    return plans
  }

  @Action
  async applyGlobalParameterChange(terms: {
    newParameter: IParameter<unknown>
    oldParameter: IParameter<unknown>
    objectId: string
    operationSheets: string[]
  }): Promise<void> {
    await axios({
      method: 'patch',
      url: `/api/operation-sheets/${this.currentSheet.id}/object/${terms.objectId}/parameter`,
      data: {
        operationSheets: terms.operationSheets,
        newParameter: terms.newParameter,
        currentParameter: terms.oldParameter,
      },
    })
  }

  /**
   * Get all the plans that contains the object with the given id and the given comments
   */
  @Action
  async getObjectCommentsRelatedPlans(terms: { comments: IComment[]; objectId: string }) {
    const plans = await request<OperationSheet[]>(
      {
        method: 'get',
        url: `/api/operation-sheets/${this.currentSheet.id}/object/${terms.objectId}/comments`,
      },
      OperationSheet
    )
    return plans
  }

  /**
   * Apply the comments changes to all the given plans
   */
  @Action
  async applyGlobalCommentsChange(terms: {
    operationSheets: string[]
    comments: IComment[]
    objectId: string
  }): Promise<void> {
    await axios({
      method: 'patch',
      url: `/api/operation-sheets/${this.currentSheet.id}/object/${terms.objectId}/comments`,
      data: {
        operationSheets: terms.operationSheets,
        comments: terms.comments,
      },
    })
  }

  @Action
  async createTPAComplex(complex: TPAComplex): Promise<TPAComplex> {
    const createdComplex = await exposableRequest(
      {
        method: 'post',
        url: `/api/surgery-objects/tpa-complex`,
      },
      complex,
      TPAComplex
    )

    return createdComplex.data
  }

  @Action
  async updateTPAComplex(complex: TPAComplex) {
    await exposableRequest(
      {
        method: 'patch',
        url: `/api/operation-sheets/${this.currentSheet.id}/object/tpa-complex/${complex.id}`,
      },
      complex,
      TPAComplex
    )
  }

  @Action
  async createColumn(save: { name: string; column: Column }): Promise<Column> {
    const createdColumn = await axios({
      method: 'post',
      url: `/api/surgery-objects/column`,
      data: save,
    })

    return createdColumn.data
  }

  @Action
  async updateColumn(save: { name: string; column: Column }) {
    await axios({
      method: 'patch',
      url: `/api/operation-sheets/${this.currentSheet.id}/object/column/${save.column.id}`,
      data: save,
    })
  }

  @Action
  async createEmbeddedColumn(save: { name: string; embeddedDeviceColumn: EmbeddedColumn }): Promise<EmbeddedColumn> {
    const createdColumn = await axios({
      method: 'post',
      url: `/api/surgery-objects/embedded-device-column`,
      data: save,
    })

    return createdColumn.data
  }

  @Action
  async updateEmbeddedColumn(save: { name: string; embeddedDeviceColumn: EmbeddedColumn }) {
    await axios({
      method: 'patch',
      url: `/api/operation-sheets/${this.currentSheet.id}/object/embedded-device-column/${save.embeddedDeviceColumn.id}`,
      data: save,
    })
  }

  @Action
  async createStand(save: { name: string; stand: Stand }): Promise<Stand> {
    const createdStand = await axios({
      method: 'post',
      url: `/api/surgery-objects/stand`,
      data: save,
    })

    return createdStand.data
  }

  @Action
  async updateStand(save: { name: string; stand: Stand }) {
    await axios({
      method: 'patch',
      url: `/api/operation-sheets/${this.currentSheet.id}/object/stand/${save.stand.id}`,
      data: save,
    })
  }

  @Action
  async updateColumnPreview(picture: File): Promise<string> {
    const file = new FormData()
    file.append('file', picture)

    const response = await axios.post(`/api/images/columns`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data.filename
  }

  @Action
  async updateTPAPreview(picture: File): Promise<string> {
    const file = new FormData()
    file.append('file', picture)

    const response = await axios.post(`/api/images/tpa-complex`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data.filename
  }

  @Action
  async getObjectRelatedPlans(objectId: string): Promise<OperationSheet[]> {
    return await request<OperationSheet[]>(
      {
        method: 'get',
        url: `/api/operation-sheets/${this.currentSheet.id}/object/${objectId}`,
      },
      OperationSheet
    )
  }

  @Action
  async replaceObject(replace: {
    currentDevice: SurgeryObject
    newDevice: SurgeryObject
    operationSheets: Array<Partial<OperationSheet>>
  }): Promise<void> {
    await axios({
      method: 'patch',
      url: `/api/operation-sheets/${this.currentSheet.id}/object`,
      data: replace,
    })
  }
}

export default OperationSheetModule
